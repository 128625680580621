export default {
  error_occurred: "Sorry, an error occurred. Please try again in a moment.",
  login: "Log in",
  sign_up: "Sign up",
  register: "Registration",
  enter_tenant_name:
    "Choose the company associated with your Calendesk account.",
  no_account_question: "Don't have an account yet?",
  do_you_already_have_an_account:
    "Do you already have an account, or do you want to log into your employer account?",
  login_title: "Log in to the Calendesk panel",
  login_tenant: "Your company name",
  company_field_placeholder: "e.g. Hair&More",
  next: "Next",
  register_subtitle: "Create a new workspace for your company in Calendesk",
  register_confirm_title: "Please confirm your email address",
  register_confirm_subtitle:
    "We have sent an email with an activation link to the email address you provided during registration.",
  register_confirm_text: "Please activate your account to access Calendesk.",
  register_data_incorrect:
    "The email address you have entered is already registered in the Calendesk system. If you have forgotten your password, please use the reminder option.",
  resend_activate_email: "Send the activation link again",
  login_email: "Your email address",
  password: "Password",
  email_or_password_incorrect:
    "The email address or password is incorrect. If you forgot your password, use the reminder option.",
  please_activate_account:
    "Please activate your account. We have sent an activation link to your email address.",
  activate_account_error_message: "The link is invalid or has expired.",
  forgotten_password: "Forgot your password?",
  forgotten_password_title: "Everyone sometimes forgets their password.",
  forgotten_password_text:
    "Please enter the email address that we should use to send you instructions on how to change your password.",
  forgotten_password_button: "Send a link to change your password",
  forgotten_password_success_title: "Check your inbox",
  forgotten_password_success_text:
    "If the email address exists in the system, we have just sent you a message with instructions on how to change your password.",
  reset_password: "Set a new password",
  reset_password_title: "Set a new password",
  reset_password_text: "Enter your new password below.",
  reset_password_text_hint:
    "The password should consist of at least 8 characters, contain an uppercase letter, a number, and a special character.",
  reset_password_button: "Save the new password",
  reset_password_fail_text:
    "The password has not been changed. The link has probably expired.",
  reset_password_success_title: "Your password has been changed.",
  reset_password_sent_email:
    "A link to set a password has been sent to the user's email address.",
  reset_password_success_text: "You can sign in with your new password.",
  reset_password_success_button: "Ok",
  profile: "Profile",
  number_of_bookings: "Number of bookings",
  number_of_new_users: "Number of new customers",
  number_of_newsletter_members: "Number of newsletter subscriptions",
  monthly_divided: "Broken down by month",
  area_code: "Area code",
  other: "Other",
  or: "or",
  short_month_1: "JAN",
  short_month_2: "FEB",
  short_month_3: "MAR",
  short_month_4: "APR",
  short_month_5: "MAY",
  short_month_6: "JUN",
  short_month_7: "JUL",
  short_month_8: "AUG",
  short_month_9: "SEP",
  short_month_10: "OCT",
  short_month_11: "NOV",
  short_month_12: "DEC",
  section_admin_panel: "Panel",
  section_admin_panel_clients: "Panel / Customers",
  section_admin_panel_newsletters: "Panel / Customers / Newsletter",
  section_admin_panel_customer_notifications:
    "Panel / Customers / Notifications",
  section_admin_panel_profile: "Panel / Profile",
  section_admin_panel_employees: "Panel / Employees",
  section_admin_panel_sales: "Panel / Sales",
  section_admin_panel_settings: "Panel / Settings",
  section_admin_panel_service: "Panel / Services",
  section_admin_panel_plugins: "Panel / Add-ons",
  section_admin_panel_payments: "Panel / Accounts and payments",
  section_admin_panel_offer: "Panel / Offer",
  section_admin_panel_tools: "Panel / Tools",
  section_admin_panel_notifications: "Panel / Notifications",
  simple_store: "Products",
  section_admin_subscriptions_list: "Panel / Subscriptions",
  last_update: "Last update",
  add_client: "Add a customer",
  add_newsletter_record: "Add email address",
  upload_photo: "Upload a photo",
  upload_photo_error_message:
    "Uploading the photo was unsuccessful. The maximum size is 10 MB.",
  upload_photos_error_message:
    "Some photos may not have been added. The maximum size is 10 MB.",
  get_company_data: "Download data",
  get_company_data_failed: "The data could not be downloaded.",
  company_name: "Company name",
  street_and_number: "Street and number",
  postcode: "Postal code",
  city: "City",
  country: "Country",
  send_email_with_password_change:
    "Send an email with a link to set the password?",
  client_data: "Customer data",
  company_business_activity: "Billing details",
  remove_image: "Remove picture",
  no_way_to_undo_action: "This action cannot be undone!",
  remove_image_warning: "Are you sure you want to delete the user's photo?",
  add_booking: "Add a booking",
  add_unavailable_booking_slot: "Add a blackout date",
  add_tag: "Add a label",
  duplicate: "Duplicate",
  add_to_group: "Add to a group",
  cancel_booking: "Cancel your booking",
  delete_booking: "Delete the booking",
  delete_booking_failed: "Deleting the selected booking has failed",
  cancel_booking_failed: "Canceling the selected booking has failed",
  selected_n_positions: "@{amount} items selected",
  remove_multiple_users: "Delete users",
  remove_multiple_employees: "Delete employees",
  remove_employee: "Delete employee",
  lock_multiple_users: "Block users",
  unlock_multiple_users: "Unblock users",
  lock_multiple_employees: "Block employees",
  unlock_multiple_employees: "Unblock employees",
  lock_employee: "Block employee",
  unlock_employee: "Unblock employee",
  user_remove_failed_bookings:
    "This user has scheduled visits or active subscriptions. You must cancel them before deleting the user.",
  employee_remove_failed_bookings_or_services:
    "This employee has scheduled visits or has already been removed. You should cancel or reassign their visits before removing an employee.",
  operation_failed:
    "An error has occurred while making changes. Please try again.",
  accounts_update_succeeded:
    "The selected user accounts have been successfully updated",
  accounts_update_failed: "Updating the selected accounts has failed",
  remove_account_warning: "Are you sure you want to delete the user account?",
  remove_accounts_warning:
    "Are you sure you want to delete the selected user accounts?",
  add_group: "Add a group",
  sort_by: "Sort",
  basic_data: "Basic information",
  public_data: "Fill in additional information about the employee",
  remove_category: "Delete category",
  remove_category_warning: "Are you sure you want to delete the category?",
  services_in_category: "Services in the category",
  services_already_assigned_in_category:
    "The services are assigned to this category. Please assign them to another category to continue.",
  assign_service_to_category: "Assign a service to a category",
  current_category: "Current category",
  services_assign_failed:
    "There was an error while assigning the selected services to the category",
  assign_n_services_to_category: "Assign @{n} services to a category:",
  category_order_info:
    "The selected order of the services and categories will be reflected on the website and in the mobile app for customers.",
  lock_account: "Block account",
  remove_account: "Delete account",
  fetch_data_failed: "There was an error while downloading data",
  edit_service: "Edit service",
  remove_service: "Delete service",
  variants: "Options",
  booking_settings: "Booking settings",
  booking_time_before: "Booking of the service no later than",
  cancel_time_before: "Cancellation of the service no later than",
  change_time_before: "Rescheduling of the service no later than",
  before_visit: "before the visit",
  slots_every_checkbox_text: "Show customers the option to book every",
  break_time_before_checkbox_text:
    "Extra time for a break before the created booking",
  break_time_after_checkbox_text:
    "Extra time for a break after the created booking",
  resources_info:
    "Select the limited resources needed during the service. The customers will not be able to book a visit online if the resources in other visits run out.",
  quantity: "Quantity",
  quantity_pcs: "Quantity (pcs)",
  value_is_too_high: "The value is too large. The maximum value is: @{number}.",
  value_is_too_low: "The value is too small. The minimum value is: @{number}.",
  resource_name: "Resource name",
  add_variant: "Add a service option",
  service_active: "Service active in the clients' calendar",
  category_active_short: "Active",
  category_inactive_short: "Inactive",
  variant_name: "Option name",
  service_blocked: "Service blocked",
  all_employees: "All employees",
  price_in_currency: "Price in @{currency}",
  no_employees_found:
    "No employees were added. You can create an employee now and assign him/her to this service. Your customers will be able to select the added employees assigned to this service.",
  add_employee_fail:
    "There was a problem while adding the employee, please try again.",
  select_employee_error: "Select at least one employee",
  service_update_failed: "Updating the service has failed. Please try again.",
  service_creation_failed: "Creating the service has failed. Please try again.",
  remove_service_warning: "Are you sure you want to delete the service?",
  remove_service_failed: "Deleting the service has failed. Please try again.",
  move: "Move",
  tag_visits: "Labeled visits",
  services_with_resource: "Services with a resource",
  resource: "Resource",
  users_in_group: "Customers in the group",
  remove_tag_warning: 'Are you sure you want to remove the label "@{tag}"?',
  remove_tag: "Remove label",
  edit_tag: "Edit label",
  remove_resource_warning:
    'Are you sure you want to delete the resource "@{resource}"?',
  remove_resource: "Remove resource",
  edit_resource: "Edit resource",
  remove_group_warning: 'Are you sure you want to delete the group "@{group}"?',
  remove_group: "Remove group",
  edit_group: "Edit group",
  tag_info:
    "Labels describe the characteristics of customer bookings. You can assign labels to your bookings and use filters to find them later.",
  resource_info:
    "Resources determine anything you may need to perform your services — for example, a conference room, a chair, a massage table, etc.",
  resource_info_2:
    "By assigning resources to your services, you eliminate the risk of lack of equipment needed to serve your customers. You can assign resources to your services while creating or editing them.",
  group_info:
    "Groups can be helpful in customer management. Each customer can be assigned to several groups. Then, similar to labels, you can filter your customers with selected groups.",
  email_notifications: "Email notifications",
  sms_notifications: "SMS notifications",
  buy_more: "Purchase",
  notifications_count: "Number of notifications",
  pay: "Pay",
  notification_first_label: "Remaining",
  notification_second_label_email: "email notifications",
  notification_second_label_sms: "SMS notifications",
  notifications_sms_email: "SMS and email notifications",
  notifications_reminder_first:
    "Notifications can be sent immediately after creating, changing, or deleting a booking.",
  notifications_reminder_second:
    "Additionally, you can set the reminder time and choose who and how to notify.",
  notification: "Notification",
  notify_tenant: "Employee",
  notify_client: "Customer",
  notification_save_success:
    "The notifications have been updated successfully.",
  invoice_data: "Invoice details",
  service_categories: "Service categories",
  application: "App",
  email: "Email",
  currency: "PLN",
  category: "Category",
  categories: "Categories",
  service: "Service",
  service_list: "List of services",
  services: "Services",
  employee: "Employee",
  employees: "Employees",
  employees_list: "List of employees",
  plugins: "Add-ons",
  packages: "Your packages",
  plugins_mobile_drafts: "Mobile app",
  plugins_mobile_editor: "Mobile app builder",
  plugins_payments_tpay: "TPay payments",
  plugins_payments_stripe: "Stripe payments",
  settings_platform: "Platform",
  notifications: "Notifications",
  profile_company: "Your company",
  profile_account: "Your profile",
  payments: "Accounts and payments",
  settings: "Settings",
  duration: "Duration",
  from: "From",
  to: "To",
  filter: "Filter",
  close: "Close",
  today: "Today",
  tomorrow: "Tomorrow",
  this_week: "This week",
  this_month: "This month",
  this_year: "This year",
  yesterday: "Yesterday",
  last_week: "Last week",
  last_month: "Last month",
  last_year: "Last year",
  clear: "Clear",
  service_name: "Service name",
  category_name: "Category name",
  price: "Price",
  name: "First name",
  surname: "Last name",
  from_cannot_be_greater_than_to:
    "The “from” time cannot be later than the “to” time",
  name_and_surname: "Full name",
  phone: "Phone",
  field_is_required: "This field is required.",
  value_is_no_url: "The value entered is not a valid URL.",
  value_is_no_url_to_facebook:
    "The value entered is not a valid Facebook profile URL.",
  value_is_no_url_to_twitter:
    "The value entered is not a valid Twitter profile URL.",
  value_is_no_url_to_instagram:
    "The value entered is not a valid Instagram profile URL.",
  value_is_no_url_to_youtube:
    "The value entered is not a valid YouTube profile URL.",
  value_is_no_domain: "The value entered is not a domain.",
  min_n_characters: "The minimum number of characters is @{min}.",
  max_n_characters: "The maximum number of characters is @{max}.",
  email_is_incorrect: "The email address is incorrect.",
  page_not_found: "Oops! The page you were looking for could not be found :(",
  go_back_to_main_page: "Go back to the home page and try again.",
  logout: "Sign out",
  status: "Status",
  status_approved: "Approved",
  status_waiting: "Pending",
  status_payment: "To be paid",
  status_cancelled: "Canceled",
  status_done: "Completed",
  date: "Date",
  cancel: "Cancel",
  yes: "Yes",
  no: "No",
  date_from: "Date from",
  date_to: "Date to",
  nothing_found_here: "No results",
  nothing_here: "None",
  save: "Save",
  details: "Details",
  add_image: "Add picture",
  change: "Change",
  remove: "Delete",
  email_is_taken: "An account with the given email address already exists.",
  dashboard: "Dashboard",
  users: "Customers",
  users_list: "List of customers",
  calendar: "Calendar",
  add: "Add",
  resources: "Resources",
  translations: "Translations",
  web_editor: "Website editor",
  websites: "Websites",
  mobile_app_title: "Mobile application",
  preview: "Preview",
  roles: "Function",
  role_admin: "Administrator",
  role_manager: "Manager",
  role_employee: "Employee (trusted)",
  role_employee_basic: "Employee (restricted)",
  permissions: "Permissions",
  admin_access_question: "The employee can log in to the Calendesk panel",
  send_employees_app_link:
    "Send employees a link to the mobile app  to their email address",
  search: "Search",
  add_user: "Add a customer",
  add_employee: "Add an employee",
  add_category: "Add a category",
  edit_category: "Edit a category",
  add_service: "Add a service",
  add_resource: "Add a resource",
  bookings_today: "Bookings for today",
  bookings_tomorrow: "Bookings for tomorrow",
  bookings_this_week: "Bookings for this week",
  newsletter: "Newsletter",
  month_1: "January",
  month_2: "February",
  month_3: "March",
  month_4: "April",
  month_5: "May",
  month_6: "June",
  month_7: "July",
  month_8: "August",
  month_9: "September",
  month_10: "October",
  month_11: "November",
  month_12: "December",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  short_day_monday: "Mon.",
  short_day_tuesday: "Tue.",
  short_day_wednesday: "Wed.",
  short_day_thursday: "Thu.",
  short_day_friday: "Fri.",
  short_day_saturday: "Sat.",
  short_day_sunday: "Sun.",
  refresh: "Refresh",
  select_date: "Select date",
  edit: "Edit",
  ok: "Ok",
  client: "Customer",
  clients: "Customers",
  tag: "Label",
  added_tag: "The label has been added",
  added_group: "The group has been added",
  added_resource: "Added resource",
  tags: "Labels",
  phone_is_invalid: "The phone number is incorrect.",
  birthday: "Birthday",
  send_reset_password: "Send an email with a link to set the password?",
  user_created_successfully: "The customer was created successfully.",
  employee_created_successfully: "The employee was created successfully.",
  description: "Description",
  created_calendar_event_correctly: "The calendar entry has been added.",
  updated_calendar_event_correctly: "The calendar entry has been updated.",
  updated_multiple_bookings_correctly: "The bookings have been updated.",
  deleted_multiple_bookings_correctly: "The bookings have been deleted.",
  deleted_multiple_unavailable_booking_slots_correctly:
    "Blackout dates have been removed.",
  deleted_service_event_correctly: "The service has been deleted.",
  creating_calendar_event_failed:
    "Adding the entry has failed, please try again.",
  deleting_service_event_failed:
    "The deletion has failed. Check if you don't have visits scheduled for this service and try again.",
  updating_calendar_event_failed:
    "Updating the entry has failed, please try again.",
  updating_bookings_failed:
    "Updating the booking has failed, please try again.",
  deleting_bookings_failed:
    "Deleting the booking has failed, please try again.",
  block_spot:
    "An event unrelated to the customer's visit (Blackout Dates for customers)",
  block: "Block",
  unavailable_spot: "Blocked",
  id: "ID",
  per_page: "Items per page",
  groups: "Groups",
  group: "Group",
  delete: "Delete",
  facebook: "Link to a Facebook profile",
  instagram: "Link to an Instagram profile",
  youtube: "Link to a YouTube profile",
  twitter: "Link to a Twitter profile",
  active_accounts: "Active accounts",
  inactive_accounts: "Inactive accounts",
  active_account: "Active account",
  inactive_account: "Inactive account",
  visit: "Appointment",
  loading: "Loading…",
  created_at: "Created",
  canceled_at: "Canceled",
  user: "User",
  category_active: "Service category visible to customers",
  category_inactive: "Service category invisible to customers",
  email_verified_at: "Email verification",
  email_unverified: "Unverified email",
  gender: "Gender",
  gender_male: "Male",
  gender_female: "Female",
  gender_other: "Other",
  tax: "Tax ID number",
  tax_prefix: "Prefix",
  block_account: "Block account",
  unblock_account: "Unblock account",
  generate_new_password_automatically:
    "Send a link to change the password to the email address.",
  unblock_account_question: "Are you sure you want to unblock the account?",
  block_account_question: "Are you sure you want to block the account?",
  delete_user: "Delete account",
  delete_users: "Delete accounts",
  user_can_not_be_loaded: "An error has occurred. The user cannot be loaded.",
  user_bookings: "Bookings",
  user_newsletter: "Newsletter",
  title: "Title",
  color: "Color",
  no_employee_selected_in_calendar:
    "No employees were selected. Select them from the list above to see the calendar.",
  client_policy_confirmation_text:
    "The customer confirms that he/she has read the Terms and Conditions and the Privacy Policy.",
  /*
  calendar toolbar translations
   */
  listWeek: "Agenda of the day",
  resourceTimeGridDay: "Day",
  timeGridWeek: "Week",
  dayGridMonth: "Month",
  current_week: "Current week",
  current_month: "Current month",
  /*
  end of calendar toolbar translations
   */
  date_day_from: "From (day)",
  date_hour_from: "From (hour)",
  date_day_to: "To (day)",
  date_hour_to: "To (hour)",
  created_date_range: "Creation date",
  canceled_date_range: "Cancellation date",
  event_date_range: "Event date",
  payment_date_range: "Payment date",
  you_can_add_note_here: "Here you can add a private note",
  account_activation_resend_link_succeeded:
    "The activation link has been sent again",
  account_activation_resend_link_failed:
    "There was an error while generating the link, please try again",
  calendar_settings: "Calendar settings",
  minimal_length_of_calendar_event:
    "Minimum duration of the event in the calendar",
  hour_divider_interval: "Hour markings visible every",
  calendar_time_boundaries: "Calendar range",
  all_bookings: "All bookings",
  bookings: "Bookings",
  more: "more",
  visit_datetime: "Booking date&time",
  client_names: "Customer's first and last name",
  waiting: "Awaiting approval",
  payment: "Prepayment required",
  approved: "Approved",
  cancelled: "Canceled",
  done: "Completed",
  delete_succeeded: "Removed successfully",
  delete_failed: "An error has occurred during removal",
  confirmation_modal_header: "The action requires confirmation",
  confirmation_modal_warning: "Are you sure you want to proceed?",
  change_status: "Change status",
  remove_n: "Delete (@{n})",
  remove_event: "Delete event",
  editor_tool_global_settings_title: "Global settings",
  editor_tool_global_settings_subtitle: "Color and logo",
  calendesk_file_input_first: "Drop the files here or",
  calendesk_file_input_second: "choose from your hard drive",
  calendesk_file_input_third: "to add",
  assets: "Files",
  domain_configuration_title: "Domain configuration",
  domain_configuration_subtitle:
    "Choose the domain under which your website should be accessible",
  domain_configuration_calendesk_domain:
    'Please enter the name of the subdomain (alias) under which your website will be accessible, e.g. "yourcompany.calendesk.com".',
  domain_configuration_calendesk_domain_2:
    'Please enter the name of the subdomain (alias) under which your website will be accessible, e.g. "yourcompany.calendesk.net".',
  domain_configuration_own_domain_text:
    'You can also add your own domain, e.g. "yourcompany.com".',
  domain_configuration_own_domain_add_button: "Add your own domain",
  domain_configuration_own_domain_edit_button: "Edit your own domain",
  domain_configuration_footer: "Your website will be available at @{domain}",
  domain_configuration_form_alias: "Subdomain (alias), e.g. your company name",
  domain_configuration_form_domain: "Domain",
  add_domain_title: "Adding your own domain",
  add_domain_verification_error:
    "The domain is being verified. If everything has been set correctly, please wait 15 minutes and try again.",
  add_domain_verification_success: "The domain is working correctly",
  add_domain_first_step_title: "Your domain",
  add_domain_first_step_subtitle:
    "Please enter the domain name you want to link to your website",
  add_domain_first_step_domain: "Your domain",
  add_domain_first_step_confirm: "Continue",
  add_domain_second_step_title: "Domain provider panel",
  add_domain_second_step_subtitle:
    "Please log in to your domain provider panel, search for the DNS settings section and add two new records as specified in the next step.",
  add_domain_second_step_confirm: "Continue",
  add_domain_third_step_title: "Add new records",
  add_domain_third_step_subtitle:
    "Add domain records as below. For some domain operators, a CNAME record may end with a dot.",
  add_domain_third_step_subtitle_dot: "with a dot at the end",
  add_domain_third_step_confirm: "Continue",
  add_domain_third_step_dns_name: "Name",
  add_domain_third_step_dns_type: "Type",
  add_domain_third_step_dns_url: "Address",
  add_domain_fourth_step_title: "Verification of operation",
  add_domain_fourth_step_subtitle:
    "Once the domain is set up correctly, please verify the changes.",
  add_domain_fourth_step_confirm: "Verify the operation of the domain",
  web_global_logo: "Logotype",
  web_global_primary_color: "Main color",
  gallery_input_set_image: "Insert",
  no_selected_images: "No pictures selected",
  home_about_element_gallery: "Photos in the gallery",
  new_website: "New website",
  drafts_table_header_title: "Template name",
  drafts_table_header_last_updated: "Date of edition",
  published_text: "Published",
  not_published_text: "Unpublished",
  disabled: "Disabled",
  publish: "Publish",
  drafts_category_1: "Beauty",
  drafts_category_2: "Fitness",
  drafts_create_website_button: "Create a website",
  tool_save_draft: "Save the sketch",
  drafts_disable_confirm_dialog_title: "Disable the website",
  drafts_disable_confirm_dialog_warning:
    "Are you sure you want to disable the website?",
  drafts_disable_confirm_dialog_inportant_information:
    "Once disabled, your website will no longer be available online.",
  drafts_remove_confirm_dialog_title: "Remove sketch",
  drafts_remove_confirm_dialog_warning:
    "Are you sure you want to remove the sketch?",
  domain_dialog_title: "Your website address",
  domain_dialog_button: "Configure the domain",
  domain_dialog_domain_text: "Your website will be displayed at:",
  editor_domain_error_message: "The selected domain is not available",
  publish_dialog_button: "Return to sketches",
  /*
  ASSETS
   */
  files: "Files",
  assets_no_data: "No pictures added",
  assets_dialog_use_selected_file: "Use the selected file",
  assets_dialog_use_selected_files: "Use the selected files",
  remove_asset: "Delete file",
  remove_asset_warning: "Are you sure you want to delete @{file}?",
  change_name_asset_title: 'Rename the file "@{file}"',
  change_name_asset_new_name: "New filename",
  editor_image: "Picture editor",
  editor_image_confirm_button: "Apply",
  editor_image_crop_any: "Any",
  editor_image_crop_5_4: "5:4",
  editor_image_crop_7_5: "7:5",
  editor_image_crop_16_9: "16:9",
  editor_image_crop_3_2: "3:2",
  editor_image_crop_square: "Square",
  editor_image_reset: "Reset",
  editor_image_rotate_left: "Rotate 90 degrees to the left",
  editor_image_rotate_right: "Rotate 90 degrees to the right",
  editor_image_flip_x: "Flip on the X axis",
  editor_image_flip_y: "Flip on the Y axis",
  /*
  Global
   */
  form_is_invalid:
    "The form contains errors. Please check the highlighted fields and try again.",
  /*
  VALIDATIONS
   */
  no_integer: "A numeric character is required.",
  no_numeric_character: "A numeric character is missing.",
  no_lowercase_character: "A lowercase character is required.",
  no_uppercase_character: "An uppercase character is missing.",
  no_special_character: "A special character is missing.",
  /*
  SETTINGS PLATFORM
   */
  c_language_select_label: "Language",
  c_currency_select_label: "Currency",
  c_time_zone_autocomplete_label: "Time zone",
  c_language_select_polish: "Polski",
  c_language_select_german: "Deutsch",
  c_language_select_english: "English",
  c_language_select_spanish: "Español",
  c_language_select_czech: "Čeština",
  c_language_select_french: "Français",
  c_language_select_hindi: "हिन्दी",
  c_language_select_dutch: "Nederlands",
  c_language_select_portuguese: "Português",
  c_language_select_russian: "Русский",
  c_language_select_slovak: "Slovenčina",
  c_language_select_ukrainian: "Українська",
  c_language_select_bulgarian: "Български",
  c_language_select_greek: "Ελληνικά",
  c_language_select_estonian: "Eesti",
  c_language_select_finnish: "Suomi",
  c_language_select_irish: "Gaeilge",
  c_language_select_croatian: "Hrvatski",
  c_language_select_hungarian: "Magyar",
  c_language_select_lithuanian: "Lietuvių",
  c_language_select_latvian: "Latviešu",
  c_language_select_maltese: "Malti",
  c_language_select_slovenian: "Slovenščina",
  c_language_select_albanian: "Shqip",
  c_language_select_swedish: "Svenska",
  c_currency_select_pln: "Polish Złoty (PLN)",
  c_currency_select_eur: "Euro (EUR)",
  c_currency_select_usd: "United States Dollar (USD)",
  c_currency_select_gbp: "British Pound (GBP)",
  c_currency_select_jpy: "Japanese Yen (JPY)",
  c_currency_select_aud: "Australian Dollar (AUD)",
  c_currency_select_cad: "Canadian Dollar (CAD)",
  c_currency_select_chf: "Swiss Franc (CHF)",
  c_currency_select_sek: "Swedish Krona (SEK)",
  c_currency_select_nzd: "New Zealand Dollar (NZD)",
  c_currency_select_brl: "Brazilian Real (BRL)",
  c_currency_select_bgn: "Bulgarian Lev (BGN)",
  c_currency_select_czk: "Czech Koruna (CZK)",
  c_currency_select_dkk: "Danish Krone (DKK)",
  c_currency_select_hkd: "Hong Kong Dollar (HKD)",
  c_currency_select_huf: "Hungarian Forint (HUF)",
  c_currency_select_ils: "Israeli New Sheqel (ILS)",
  c_currency_select_myr: "Malaysian Ringgit (MYR)",
  c_currency_select_mxn: "Mexican Peso (MXN)",
  c_currency_select_twd: "New Taiwan Dollar (TWD)",
  c_currency_select_nok: "Norwegian Krone (NOK)",
  c_currency_select_php: "Philippine Peso (PHP)",
  c_currency_select_ron: "Romania Leu (RON)",
  c_currency_select_rub: "Russian Ruble (RUB)",
  c_currency_select_sgd: "Singapore Dollar (SGD)",
  c_currency_select_thb: "Thai Baht (THB)",
  c_currency_select_uah: "Ukrainian Hryvnia (UAH)",
  c_currency_select_inr: "Indian Rupee (INR)",
  c_currency_select_clp: "Chilean Peso (CLP)",
  c_currency_select_zar: "South African Rand (ZAR)",
  c_currency_select_bif: "Burundian Franc (BIF)",
  c_currency_select_djf: "Djiboutian Franc (DJF)",
  c_currency_select_gnf: "Guinean Franc (GNF)",
  c_currency_select_kmf: "Comorian Franc (KMF)",
  c_currency_select_krw: "South Korean Won (KRW)",
  c_currency_select_mga: "Malagasy Ariary (MGA)",
  c_currency_select_pyg: "Paraguayan Guarani (PYG)",
  c_currency_select_rwf: "Rwandan Franc (RWF)",
  c_currency_select_ugx: "Ugandan Shilling (UGX)",
  c_currency_select_vnd: "Vietnamese Dong (VND)",
  c_currency_select_vuv: "Vanuatu Vatu (VUV)",
  c_currency_select_xaf: "CFA Franc BEAC (XAF)",
  c_currency_select_xof: "CFA Franc BCEAO (XOF)",
  c_currency_select_xpf: "CFP Franc (XPF)",
  /*
  PROFILE COMPANY
   */
  profile_company_name_label_input: "Company name",
  profile_company_name_placeholder_input: "e.g. Hair&More",
  profile_company_contact_header: "Contact details",
  profile_company_contact_address_label_autocomplete: "Address",
  profile_company_contact_email_label_input: "Email address",
  profile_company_contact_country_autocomplete: "Country",
  profile_company_work_hours_header: "Working days and hours",
  profile_company_social_media_header: "Social media",
  profile_company_social_media_facebook_label_input:
    "Link to a Facebook profile",
  profile_company_social_media_twitter_label_input: "Link to a Twitter profile",
  profile_company_social_media_instagram_label_input:
    "Link to an Instagram profile",
  profile_company_social_media_youtube_label_input: "Link to a YouTube profile",
  profile_company_regulations_header: "Terms and Conditions",
  profile_company_regulations_label_textarea: "Terms and Conditions",
  profile_company_privacy_policy_header: "Privacy Policy",
  profile_company_privacy_policy_label_textarea: "Privacy Policy",
  profile_company_contact_street_label_input: "Street and number",
  profile_company_contact_zip_code_label_input: "Postal code",
  profile_company_contact_city_label_input: "City",
  plugins_store_all_plugins_tab: "All add-ons",
  plugins_mobile_drafts_header_button: "Edit the mobile app",
  create_mobile_draft_dialog_title: "Choose a template",
  create_mobile_draft_dialog_name_label_input: "Template name",
  create_mobile_draft_dialog_name_label_input_placeholder: "e.g. My app",
  create_mobile_draft_dialog_select_category: "Category",
  create_mobile_draft_dialog_submit: "Save and continue",
  plugins_mobile_drafts_new_application_button: "New mobile app",
  plugins_mobile_drafts_disable_confirm_dialog_title: "Disable the mobile app",
  plugins_mobile_drafts_disable_confirm_dialog_warning:
    "Are you sure you want to disable the mobile app?",
  plugins_mobile_drafts_disable_confirm_dialog_important_information:
    "Once disabled, your app will no longer be available online.",
  plugins_mobile_drafts_remove_confirm_dialog_title: "Remove sketch",
  plugins_mobile_drafts_remove_confirm_dialog_warning:
    "Are you sure you want to remove the sketch?",
  plugins_mobile_preview_information_title: "Preview changes on your phone",
  plugins_mobile_preview_information_paragraph_1:
    "Publish the template. Download the Calendesk app and enter the name on the first screen",
  plugins_mobile_preview_information_paragraph_2:
    "as the company ID. Then log in to your user account using your email address and password.",
  plugins_mobile_preview_information_paragraph_3:
    "When you log in, the app will automatically refresh when you make changes here.",
  plugins_mobile_preview_text_button: "Send link to the app",
  send_mobile_app_link_dialog_title: "Send link to the app",
  send_mobile_app_link_dialog_email_tab_title: "Email",
  send_mobile_app_link_dialog_email_tab_input_label:
    "Email address to which we should send the link to the app",
  send_mobile_app_link_dialog_email_tab_submit_button: "Send email",
  send_mobile_app_link_dialog_sms_tab_title: "SMS",
  send_mobile_app_link_dialog_sms_tab_input_label:
    "The phone number to which we should send the link to the app",
  send_mobile_app_link_dialog_sms_tab_submit_button: "Send SMS",
  publish_mobile_draft_dialog_title: "The mobile app is being published",
  publish_mobile_draft_dialog_subtitle:
    "It may take a few minutes before the app is visible on the Internet.",
  publish_mobile_draft_dialog_button: "Return to sketches",
  mobile_home_form_title: "Main view",
  mobile_home_form_logo_label: "Background photo",
  mobile_home_form_logo_select_button: "Select",
  mobile_home_form_slogan_label: "Header",
  mobile_home_form_signup_button: "Registration button",
  mobile_services_form_title: "Services",
  mobile_services_form_menu_services_label: "Bookmark",
  mobile_services_form_services_title: "Header",
  mobile_services_form_services_input_label: "Search",
  mobile_services_form_services_search_empty_screen_subtitle_label:
    "No results",
  mobile_services_form_services_types_title_label: "Service options",
  mobile_services_form_cancel_booking_success_button_label: "Other button",
  mobile_services_form_logo_label: "Background photo",
  mobile_services_form_logo_select_button: "Select",
  mobile_about_us_form_title: "About us",
  mobile_about_us_form_location_title: "Location header",
  mobile_about_us_form_location_info:
    "You can set the address in the main Calendesk panel in Settings / Your company. Deleting the address will also hide it in the app.",
  mobile_about_us_form_location_2_info:
    "Complete the address in the main Calendesk panel in Settings / Your company to show the location of your company in this section.",
  mobile_about_us_form_menu_about_us_label: "Bookmark",
  mobile_about_us_form_about_us_about_us_title_label:
    "Header for the description",
  mobile_about_us_form_contact_info:
    "You can set the phone number and opening hours in the Calendesk panel in Settings / Your company. If you want to hide the above sections, delete their contents.",
  mobile_about_us_form_employee_info:
    "Employees visible in this section can be set in the Calendesk panel in the Employees tab.",
  mobile_about_us_form_bookings_info:
    "In the preview on the right, you can see example bookings.",
  mobile_about_us_form_about_us_employees_title_label: "Header for employees",
  mobile_about_us_form_about_us_contact_title_label: "Header for the contact",
  mobile_about_us_form_about_us_text_label: "Text",
  mobile_about_us_form_logo_label: "Background photo",
  mobile_about_us_form_logo_select_button: "Select",
  mobile_bookings_form_title: "Bookings",
  mobile_bookings_form_menu_bookings_label: "Bookmark",
  mobile_bookings_form_menu_active_bookings_label: "Active bookings",
  mobile_bookings_form_menu_previous_bookings_label: "Previous bookings",
  mobile_account_form_title: "Profile",
  mobile_account_form_menu_bookings_label: "Bookmark",
  mobile_account_editor_info_message:
    "Select the section below that you want to edit. You can download the customer mobile app from @{google} or @{apple} and see the result as soon as it is published.",
  mobile_account_editor_info_message_2:
    "After downloading the application, enter your company name (@{company}) on the first screen.",
  mobile_account_editor_home_image_message:
    "The suggested size of the main picture is 600x800 pixels.",
  mobile_account_editor_global_logo_image_message:
    "The suggested size of the logo is 600x800 pixels.",
  // Payments
  add_payment_method_dialog_subtitle:
    "Please enter the data of the card that you will be using for Calendesk payments. Calendesk does not store your card data – the data is transferred securely through Stripe.",
  card_number: "Card number",
  date_of_expiry: "Expiration date",
  ccv: "CCV",
  card_stored_correctly: "The data has been saved successfully.",
  enter_card_data_error: "Please enter the correct card data.",
  // Packages and plans
  plans: "Plans",
  activate_plan: "Activate the plan",
  current_plan: "Current plan",
  plan_valid_till: "valid until",
  plans_headline: "Flexible pricing to suit your business",
  plans_headline_2:
    "Facilitate your booking progress and take your business further with modern solutions!",
  monthly_payment: "Monthly payment",
  annual_payment: "Annual payment",
  for_free_forever: "for free forever",
  monthly_no_vat: "month",
  yearly_no_vat: "year",
  once_no_vat: "one-time fee",
  currently_used: "You are currently using",
  cancel_plan_generic_title:
    "Before you make a decision, remember that we are always here for you! 💬",
  cancel_plan_generic_info:
    "Chat with us to find the most beneficial solution for you 🤝 or arrange a free online consultation with us, during which we will answer all your questions.",
  cancel_plan: "I confirm the cancellation 😢",
  cancel_plan_1: "Me and my clients will lose access to the booking system.",
  cancel_plan_2: "Cancel subscription",
  lower_plan: "Downgrade your plan",
  cancel_subscription_title:
    "Are you sure you want to cancel the subscription? 😔",
  cancel_subscription_title_2:
    "You are currently using a free trial period. Your subscription will automatically expire if you do not finalize the purchase.",
  cancel_subscription_subtitle_2: "No, I'm staying! 😊",
  cancel_subscription_subtitle_3:
    "You cannot cancel the plan during the trial period.",
  cancel_subscription_text: "Your subscription will end on",
  cancel_subscription_text_2:
    "All paid features will still be available for use until the end of the subscription period.\t",
  cancel_subscription_text_3: "Instead of cancelling, I choose a lower plan.",
  cancel_subscription_text_4: "Plan @{plan} for only @{price} (excluding VAT).",
  plans_advantage_1: "Unlimited number of employees",
  plans_advantage_2: "Administration panel",
  plans_advantage_3: "Calendar and booking system",
  plans_advantage_4: "Unlimited number of customers",
  plans_advantage_5: "Visit history",
  plans_advantage_6: "Resources related to bookings",
  plans_advantage_7: "Work schedule",
  plans_advantage_8: "Online payments at the booking stage",
  plans_advantage_9: "Email notifications",
  plans_advantage_10: "SMS package for starting – 50 messages",
  plans_advantage_11: "SMS package – 100 messages per month",
  plans_advantage_12: "SMS package – 200 messages per month",
  plans_advantage_13: "Technical support",
  plans_advantage_14: "Website (Calendesk subdomain)",
  plans_advantage_15: "Mobile app for employees",
  plans_advantage_16: "Mobile app for customers",
  plans_advantage_17: "Independent mobile app for customers",
  plans_advantage_18: "Dedicated account manager",
  plans_advantage_19: "Privacy Policy and Terms and Conditions",
  plans_advantage_20: "Website (own domain)",
  plans_advantage_21: "SSL website certificate",
  plans_advantage_22: "1 employee",
  plans_advantage_23: "Up to 5 employees",
  plans_advantage_24: "Up to 250 customers",
  order_plan_summary_title: "Order summary",
  monthly_subscription: "Monthly subscription",
  yearly_subscription: "Annual subscription",
  plan: "Plan",
  summary_payment: "Payment",
  summary: "Total",
  promo_code: "Discount code",
  promo_code_is_invalid: "The discount code has expired or is incorrect",
  promo_code_is_valid: "The discount code has been added to the order",
  continue: "Continue",
  continue_and_pay: "Continue and pay",
  payment_method_deleted_successfully: "The payment method has been deleted.",
  payment_method_delete_failed:
    "The payment method has not been deleted, please try again.",
  subscription_set_successfully: "Subscription set up correctly.",
  subscription_cancelled_successfully: "The subscription was canceled :(",
  // Invoices
  payment_method: "Payment method",
  add_payment_method: "Add a payment method",
  change_payment_method: "Change the payment method",
  delete_payment_method: "Delete",
  my_invoices: "My invoices",
  payment_history: "Payment history",
  download_pdf: "Download PDF",
  invoice_data_updated_successfully:
    "Invoice data has been updated successfully",
  invoice_data_update_failed:
    "Invoice data has not been updated, please try again.",
  tenant: "Company",
  created: "Confirmation",
  deleted: "Remove",
  updated: "Change",
  reminder: "Reminder",
  invoice_number: "Invoice number",
  invoice_issued_at: "Date of issue",
  invoice_total: "Amount",
  invoice_vat: "VAT",
  invoice_description: "Description",
  buy_package_success:
    "Thank you for the purchase! It may take a few seconds for activation.",
  buy_package_failed:
    "There was a problem with your purchase. Please make sure that your card is working and accepts payment.",
  buy_package_confirmation_button: "Buy the package",
  buy_package_confirmation_question:
    "Are you sure you want to buy the package? Your card will be charged.",
  incorrect_time: "Invalid time",
  web_editor_color_1_label: "Approval button color",
  web_editor_color_2_label: "Filter",
  web_editor_color_3_label: "Cancel button color",
  web_editor_color_4_label: "Loading animation color",
  web_editor_color_5_label: "Background color",
  web_editor_color_6_label: "Text color",
  web_editor_color_7_label: "Brighter text color",
  web_editor_color_8_label: "Background color",
  web_editor_color_9_label: "Link color",
  web_editor_color_10_label: "Accent color",
  web_editor_color_11_label: "Background color",
  web_editor_color_12_label: "Background color",
  web_editor_color_13_label: "Slider color",
  web_editor_color_14_label: "Background color",
  web_editor_color_15_label: "Text color",
  web_editor_color_16_label: "Background color",
  web_editor_color_17_label: "Icon color",
  web_editor_color_18_label: "Link color",
  contact_data_info:
    "The contact details will make it easier for your customers to find your business, for example, if you use the Calendesk mobile app for customers.",
  service_info_7:
    "If you turn on the prepayment option, your customers will need to pay before bookings are approved. Until then, we will lock the bookings time in the calendar to give some time to your customers to complete their payments. We can delete unpaid bookings after some time and release your calendar. See the option below.",
  awaiting_booking_payment_time: "Maximum time to pay for a booking",
  service_description:
    "A brief overview of the service will be shown when choosing the service.",
  company_data_saved: "Your company details have been saved successfully",
  company_data_failed:
    "Sorry, an error occurred. Your company details were not saved, please try again.",
  closed: "Closed",
  not_working: "Doesn't work",
  generate_regulations: "Generate",
  generate_regulations_success:
    "The Terms and Conditions and the Privacy Policy have been generated successfully",
  generate_regulations_fail:
    "There was a problem while generating the Terms and Conditions and the Privacy Policy, please try again.",
  generate_regulations_info:
    "Generate terms of service and privacy policy that can be used on your Calendesk website. These documents are created for companies that manage online bookings and process payments. Enter the full details of your company and click 'Generate'. Add the documents to your website using the Calendesk website builder, selecting sections from the 'Other' category. Remember, the generated documents are only samples and the final version should be consulted with a lawyer. Calendesk is not responsible for the content of your documents.",
  regulations_and_terms_title: "Document generator",
  full_company_name: "Full name of your company",
  full_company_name_example: "e.g. Pro&More Ltd.",
  full_company_address: "The company's registration address",
  full_company_address_example: "e.g. 123 Elm Street, Boston, MA 02116",
  regulations_data_services_1_label:
    "For which services are you creating regulations?",
  regulations_data_services_1_example: "e.g. legal, psychological, cosmetic",
  regulations_data_services_2_label: "What services do you provide?",
  regulations_data_services_2_example: "e.g. legal, psychological, cosmetic",
  regulations_data_company_url_label: "URL address of the website or calendar",
  regulations_data_company_email_label: "Contact email for the company",
  regulations_data_company_email_example: "e.g. contact@mywebsite.com",
  regulations_data_company_phone_label: "Contact phone for the company",
  regulations_data_company_phone_example: "e.g. +12225554444",
  regulations_data_date_label: "The regulations apply from the day of",
  example_short: "e.g.",
  privacy_policy_description_too_long:
    "The privacy policy text is too long (200,000 characters).",
  regulations_description_too_long:
    "The regulation text is too long (200,000 characters).",
  copy_data: "Copy data",
  no_employees_info:
    "You don’t have any employees yet, click below to add the first one.",
  no_users_info:
    "You don’t have any customers yet, click below to add the first one.",
  no_bookings_info:
    "You don’t have any bookings yet, click below to add the first one.",
  no_services_info:
    "You don’t have any services yet, click below to add the first one.",
  no_categories_info:
    "You don’t have any categories yet, click below to add the first one.",
  no_newsletter_info:
    "You do not have email addresses in the newsletter yet, click below to add the first one.",
  employee_position: "Function",
  employee_position_example: "e.g. Manager",
  employee_note_description:
    "Here you can show off your employee, this information will be displayed on the website and in the mobile app.",
  employee_permission_info:
    "You can give your employee permissions and allow them to log into the system.",
  employee_permission_employee: "Employee (trusted)",
  employee_permission_employee_basic: "Employee (restricted)",
  employee_permission_manager: "The manager",
  employee_permission_admin: "The administrator",
  employee_permission_employee_info:
    "Employees with this role have complete control of their own and other employees' calendars. They can move bookings between employees. They have access to all customers' accounts, bookings, and payments. They don't have access to other employees' accounts. They can't edit websites and mobile applications. They can't change the main system settings.",
  employee_permission_employee_basic_info:
    "Employees with this role can only view their own bookings calendar. They can display, add, edit and delete bookings only within their accounts. They can search for customers while adding a booking and see their basic details. They can't delete or edit customers' data. They don't have access to the data of other employees and their bookings.",
  employee_permission_manager_info:
    "Accounts with this role have the same permissions as trusted employees. Additionally, they can manage other employees' accounts, services, categories, websites and mobile applications. They can't manage Calendesk packages and they don't have access to global integrations (e.g., Stripe payment system)",
  employee_permission_admin_info:
    "Administrator accounts have full rights to all functions in the Calendesk panel.",
  choose_role: "Select function",
  employee_app_info:
    "Your employees can view the calendar and customers in a specially prepared mobile app available for iOS and Android. Check the box below if you want us to send an email with a link to download the app.",
  employee_public_checkbox:
    "This employee is visible to customers and accepts bookings through the online calendar",
  employee_public_info:
    "You can fill in additional (optional) information about the employee. You can later use these data in special sections, e.g., on the website or mobile application.",
  employee_working_hours: "Availability schedule",
  employee_working_hours_info:
    "We will use a selected availability schedule for services that rely on employees' availability.",
  color_in_calendar: "Calendar events color",
  delete_success: "Successfully deleted.",
  update_success: "Successfully saved.",
  web_editor_color_19_label: "Text color",
  skip: "Skip",
  configuration: "Setup",
  start_configuration: "Let's get started",
  fill: "Complete",
  filled: "Completed",
  create: "Create",
  enable: "Enable",
  disable: "Disable",
  mobile_home_form_go_to_login_label: "Go to sign in",
  accept_terms_and_conditions_1: "By signing up, I agree to the",
  accept_terms_and_conditions_2: "Terms and Conditions",
  accept_terms_and_conditions_3: "and I confirm that I have read the",
  accept_terms_and_conditions_4: "Privacy Statement.",
  change_currency: "Change currency",
  change_currency_info: "Change of currency for all services.",
  web_editor_color_20_label: "Slogan color",
  copy: "Copy",
  copied_to_clipboard: "Copied to the clipboard",
  stripe: "Stripe",
  stripe_description:
    "You can accept customer payments in real-time using Stripe, an advanced payment operator.",
  go_to_stripe: "Go to Stripe",
  stripe_info_step_1:
    'Click "Create a Stripe account" to go to Stripe and create an account. Once completed, your payment account will be linked to Calendesk. Calendesk will not have access to your account. Stripe is an independent payment operator. Connecting the Stripe account to Calendesk will allow us to create payments on your behalf – on your website and in your mobile app.',
  stripe_info_step_2: "Go to Stripe to complete the account creation process.",
  stripe_info_step_3:
    "Your Stripe account has been successfully created. Stripe is now verifying your information.",
  stripe_info_step_4:
    'Your Stripe account has been connected successfully. You can accept payments from customers. Remember to check the "Prepayment" option for services requiring prepayment.',
  stripe_title_step_1: "Create a Stripe account",
  stripe_title_step_2: "Account creation in progress",
  stripe_title_step_3: "Account verification",
  stripe_title_step_4: "Account connected",
  allow_online_payments: "Payment",
  allow_online_payment_2: "Enable online payments for this service.",
  payment_required: "Prepayment",
  payment_required_2: "Require prepayment before confirming a booking.",
  service_payments: "Payment settings",
  paid_online: "Payment",
  paid: "Paid",
  not_paid: "Unpaid",
  navbar_element_form_home_label: "Home page",
  user_payments: "Payments",
  past_due_subscription_title: "Your account has been temporarily blocked 😵",
  past_due_subscription_subtitle_1:
    "We are sorry to inform you that your Calendesk account has been temporarily blocked due to unpaid bills. We have tried to charge your card several times but have been unsuccessful. We encourage you to check your card and try to make the payment again.",
  past_due_subscription_subtitle_2:
    "If you want to change your card, you can do so below. Once the payment is made, your account will be automatically unblocked. If you have any questions or doubts, please get in touch with us through chat. We will be happy to answer your doubts and help solve the payment problem.",
  past_due_subscription_subtitle_3:
    "We are sorry to inform you that the Calendesk account has been temporarily blocked due to unpaid bills. Please contact the account administrator or the person responsible for payments as soon as possible.",
  retry_past_due_invoice_title: "Retry charge",
  past_due_subscription_title_2:
    "There is an outstanding payment on your account",
  past_due_subscription_subtitle_4:
    "Please make the payment to avoid automatic account blocking.",
  past_due_subscription_subtitle_5:
    "While there are unsettled payments on your account, changes or cancellations of subscriptions are not possible.",
  past_due_subscription_subtitle_6:
    "If you want to change your card, you can do so below. If you have any questions or doubts, please get in touch with us through chat. We will be happy to answer your doubts and help solve the payment problem.",
  select_plan: "Select a plan",
  subscription_will_not_renew_info: "Your plan will not renew automatically",
  no_email_for_password_reset_info:
    "You can't set a password if the selected user doesn't have an email address provided.",
};
